import {useEffect} from "react";
import {useNavigate} from "react-router-dom";
import {signinRedirectCallback} from "./user-service";
import LoadingPage from "./LoadingPage";

const SignInOidc = () => {
    const navigate = useNavigate();

    useEffect(() => {
        signinRedirectCallback().then(() => {
            window.history.replaceState(null, "", "/");

            navigate(localStorage.getItem('CallbackRedirect') ?? "/");
        });
    }, [navigate]);

    return <LoadingPage />;
};

export default SignInOidc;
